//=require _commom/jquery.min.js
//=require _commom/html5shiv.min.js
//=require _commom/respond.min.js
//=require ../../../../../../node_modules/bootstrap/dist/js/bootstrap.min.js
//=require ../../../../../../node_modules/owl.carousel/dist/owl.carousel.js
//=require ../../../../../../node_modules/jquery-mask-plugin/dist/jquery.mask.min.js
//=require ../../../../../../node_modules/jquery.localscroll/jquery.localScroll.min.js
//=require ../../../../../../node_modules/jquery.scrollto/jquery.scrollTo.min.js

//Loader
jQuery(window).load(function() {
    "use strict";
    // Page Preloader
    jQuery("#loader")
        .delay(350)
        .fadeOut(function() {
            jQuery("body")
                .delay(350)
                .css({ overflow: "visible" });
        });
});

$(".telefone").mask("(00)00000-0000");
$(".data").mask("00/00/0000");

//Menu Rolagem
$("#rollLinks").localScroll({
    target: "body",
    offset: -69
});

//Menu Mobile
$(".botaoResponsivo").on("click", function() {
    $(this).toggleClass("ativo");
    $("#menu").toggleClass("ativo");
});

//Valida E-mail
function IsEmail(email) {
    var exclude = /[^@\-\.\w]|^[_@\.\-]|[\._\-]{2}|[@\.]{2}|(@)[^@]*\1/;
    var check = /@[\w\-]+\./;
    var checkend = /\.[a-zA-Z]{2,3}$/;
    if (
        email.search(exclude) != -1 ||
        email.search(check) == -1 ||
        email.search(checkend) == -1
    ) {
        return false;
    } else {
        return true;
    }
}

//Envia E-mail
$("#formContato").submit(function(e) {
    e.preventDefault();

    $("#btnEnviarContato").html(
        '<i class="fa fa-spinner fa-spin"></i> Aguarde. Enviando.'
    );

    var nome = $("#nome").val();
    var email = $("#email").val();
    var mensagem = $("#mensagem").val();
    var erros = 0;

    if (nome == "") {
        $("#nome").addClass("errorField");
        erros++;
    } else {
        $("#nome").removeClass("errorField");
    }
    if (email == "" || !IsEmail(email)) {
        $("#email").addClass("errorField");
        erros++;
    } else {
        $("#email").removeClass("errorField");
    }
    if (mensagem == "") {
        $("#mensagem").addClass("errorField");
        erros++;
    } else {
        $("#mensagem").removeClass("errorField");
    }

    if (erros == 0) {
        $.post(
            url + "/email/form_contato.php",
            $("#formContato").serialize(),
            function(data) {
                if (data == "1") {
                    $(".erroContato").fadeOut("slow");
                    $("#formContato")[0].reset();
                    $(".sucessoContato").fadeIn("slow");
                    $("#btnEnviarContato").html("Enviar");
                }
            }
        );
    } else {
        $(".erroContato").fadeIn("slow");
        $("#btnEnviarContato").html("Enviar");
    }
});

//Form Cadastra Usuário
$("#formCadastro").submit(function(e) {
    e.preventDefault();

    $("#btnEnviaCadastro").html(
        '<i class="fa fa-spinner fa-spin"></i> Aguarde. Cadastrando.'
    );

    var nome = $("#nome").val();
    var telefone = $("#telefone").val();
    var email = $("#email").val();
    var senha = $("#senha").val();
    var nascimento = $("#nascimento").val();
    var erros = 0;

    if (nome == "") {
        $("#nome").addClass("errorField");
        erros++;
    } else {
        $("#nome").removeClass("errorField");
    }
    if (email == "" || !IsEmail(email)) {
        $("#email").addClass("errorField");
        erros++;
    } else {
        $("#email").removeClass("errorField");
    }
    if (telefone == "") {
        $("#telefone").addClass("errorField");
        erros++;
    } else {
        $("#telefone").removeClass("errorField");
    }
    if (senha == "") {
        $("#senha").addClass("errorField");
        erros++;
    } else {
        $("#senha").removeClass("errorField");
    }
    if (nascimento == "") {
        $("#nascimento").addClass("errorField");
        erros++;
    } else {
        $("#nascimento").removeClass("errorField");
    }

    if (erros == 0) {
        $.post(
            url + "/ajax/cadastro.php",
            $("#formCadastro").serialize(),
            function(data) {
                if (data == "1") {
                    $(".erroCadastro").fadeOut("slow");
                    $("#formCadastro")[0].reset();
                    $(".sucessoCadastro").fadeIn("slow");
                    $("#btnEnviaCadastro").html("Cadastrar");
                }
                if (data == "2") {
                    $(".erroCadastro").fadeOut("slow");
                    $(".sucessoCadastro").fadeOut("slow");
                    $(".jaCadastro").fadeIn("slow");
                    $("#formCadastro")[0].reset();
                    $("#btnEnviaCadastro").html("Cadastrar");
                }
            }
        );
    } else {
        $(".erroCadastro").fadeIn("slow");
        $("#btnEnviaCadastro").html("Cadastrar");
    }
});

//Form Nova Senha
$("#formEsqueciSenha").submit(function(e) {
    e.preventDefault();

    $("#btnEnviaNovaSenha").html(
        '<i class="fa fa-spinner fa-spin"></i> Aguarde. Buscando Cadastro.'
    );

    var email = $("#email").val();
    var erros = 0;

    if (email == "" || !IsEmail(email)) {
        $("#email").addClass("errorField");
        erros++;
    } else {
        $("#email").removeClass("errorField");
    }

    if (erros == 0) {
        $.post(
            url + "/email/form_nova_senha.php",
            $("#formEsqueciSenha").serialize(),
            function(data) {
                if (data == "1") {
                    $(".erroNovaSenha").fadeOut("slow");
                    $(".semNovaSenha").fadeOut("slow");
                    $("#formEsqueciSenha")[0].reset();
                    $(".sucessoNovaSenha").fadeIn("slow");
                    $("#btnEnviaNovaSenha").html("Enviar Nova Senha");
                }
                if (data == "2") {
                    $(".erroNovaSenha").fadeOut("slow");
                    $(".sucessoNovaSenha").fadeOut("slow");
                    $(".semNovaSenha").fadeIn("slow");
                    $("#formEsqueciSenha")[0].reset();
                    $("#btnEnviaNovaSenha").html("Enviar Nova Senha");
                }
            }
        );
    } else {
        $(".erroNovaSenha").fadeIn("slow");
        $("#btnEnviaNovaSenha").html("Enviar Nova Senha");
    }
});

function buscaDownloads() {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("busca");
    filter = input.value.toUpperCase();
    table = document.getElementById("tableDownloads");
    tr = table.getElementsByTagName("tr");

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[0];
        if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                tr[i].style.display = "";
            } else {
                tr[i].style.display = "none";
            }
        }
    }
}
